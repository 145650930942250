import { useToast } from '@/components/ui/use-toast';
import { messaging } from '@/lib/firebase/firebase';
import { MessagePayload, getToken, onMessage } from "firebase/messaging";
import { useEffect, useState } from 'react';

export const registerServiceWorkers = async () => {
    if ("serviceWorker" in navigator) {
        try {
            const workers = ["/firebase-messaging-sw.js", "/service-worker.js"];
            await Promise.all(workers.map(async (worker) => {
                try {
                    await navigator.serviceWorker.register(worker);
                } catch (err) {
                    console.error(err, "Error al registrar el Service Worker");
                }
            }));
        } catch (error) {
            console.error("Error al registrar los Service Workers:", error);
        }
    }
};


export default function useHandlerNotification({ listenChanges = true }: { listenChanges: boolean } = { listenChanges: true }) {
    const [token, setToken] = useState<null | string>(null)
    const { toast } = useToast()

    useEffect(() => {
        if (messaging && listenChanges) {
            // On listening a new messagin push;
            onMessage && onMessage(messaging, message => {
                handleNotification(message)
            })
        }
    }, [messaging, listenChanges])

    const handleGenerateTokenFCM = async () => new Promise(async (resolve) => {
        try {
            if (!messaging) return resolve(null);

            if (!('Notification' in window)) return resolve(null);

            await Notification.requestPermission()

            if (Notification.permission !== 'granted') return resolve(null);
            // Si el navegador no admite service workers, aún podemos intentar obtener el token.
            if (!('serviceWorker' in navigator)) {
                console.warn('Service Worker no es compatible con este navegador. Intentando obtener el token sin él.');
            }

            // Si el navegador admite service workers, intentamos obtener el registro del service worker.
            let serviceWorkerRegistration: ServiceWorkerRegistration | undefined = undefined;
            if ('serviceWorker' in navigator) {
                serviceWorkerRegistration = await navigator.serviceWorker.getRegistration();
                // Si no hay un registro de service worker, lo registramos (En teoria)
                if (!serviceWorkerRegistration) await registerServiceWorkers();
            }

            await navigator.serviceWorker.ready;

            const limit: number = 3;
            let increment: number = 0
            let fcmToken: null | string = null

            // Con este codigo nos aseguramos que siempre se obtenga el token fcm ya que hay casos que no da el token a la primera.
            while (increment <= limit) {
                if (fcmToken) break;
                try {
                    const generatedToken = await getToken(messaging);
                    fcmToken = generatedToken;
                } catch (error) {
                    console.error('Error al generar el token FCM:', error);
                }

                increment++;
            }

            // Si fcm no existe, retornamos null;
            if (!fcmToken) return resolve(null);
            localStorage.setItem('fcm', fcmToken);
            setToken(fcmToken);
            return resolve(fcmToken);
        } catch (error) {
            console.error('Error al generar el token FCM:', error);
            return resolve(null)
        }
    })

    const handleNotification = (message: MessagePayload) => {
        const { data, notification } = message || {}
        const { body, title } = notification || {}

        toast({
            title,
            description: body,
            duration: 3000,
        })
    }

    return { handleGenerateTokenFCM, token }
}

