import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { Messaging, getMessaging, isSupported } from 'firebase/messaging';

let firebaseConfig: FirebaseOptions | null = null;

let firebaseApp: FirebaseApp | null = null
let messaging: Messaging | null = null
let firebaseAuth: Auth | null = null

if (typeof window !== 'undefined' && typeof navigator !== 'undefined') {
    firebaseConfig = {
        apiKey: "AIzaSyAnnwVdsjnqv7FHJ04mxtCB-KuovOSMsq4",
        authDomain: "ventia-app.firebaseapp.com",
        projectId: "ventia-app",
        storageBucket: "ventia-app.appspot.com",
        messagingSenderId: "192130910081",
        appId: "1:192130910081:web:1745110f8661c9968c4a80",
        measurementId: "G-PMLWN15J5D"
    };
    firebaseApp = initializeApp(firebaseConfig);
    firebaseAuth = getAuth(firebaseApp);
    isSupported().then((supported) => {
        if (supported && firebaseApp) {
            messaging = getMessaging(firebaseApp);
        } else {
            console.error("This environment does not support Firebase Messaging.");
        }
    }
)
} else {
    console.error("This environment does not support Firebase Messaging.");
}

export { firebaseApp, firebaseAuth, firebaseConfig, messaging };

